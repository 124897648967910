import React from 'react';

const FlightSubInfo = ({ time, bookingClass }) => {
	return (
		<div className='flight__sub-info'>
			<span>Travel Time {time}</span> | <span>{bookingClass}</span>
		</div>
	);
};

export default FlightSubInfo;
