import { post } from '../../Utilities/api';
import { setDashBoard } from './adminActions';
import { setRedirect } from './redirectActions';
export const logIn =
	({ email, password }) =>
	async (dispatch) => {
		try {
			const authRequired = false;
			let res = await post(
				'/api/v1/auth/sign-in',
				{ email, password },
				authRequired
			).catch((err) => {
				throw new Error(err.message);
			});
			if (res.data && res.data.token) {
				localStorage.setItem('authToken', res.data.token);

				dispatch(setDashBoard());
				dispatch({
					type: 'LOGIN_SUCCESS',
					payload: res.data.token,
				});
			}
		} catch (error) {
			return { error, bool: false };
		}
	};

export const setAuthToken = (token) => async (dispatch) => {
	localStorage.setItem('authToken', token);
	dispatch({
		type: 'LOGIN_SUCCESS',
		payload: token,
	});
};

//res comes from src/API/authAPI
export const signOut = () => async (dispatch) => {
	localStorage.clear();
	dispatch({ type: 'LOGOUT_SUCCESS' });
	dispatch(setRedirect('/'));
};

//res comes from src/API/authAPI
export const signUp = (res) => {
	return (dispatch) => {
		let { payload, bool } = res;
		if (!!bool === true) {
			dispatch({ type: 'LOGIN_SUCCESS', payload });
		} else {
			dispatch({ type: 'LOGIN_ERROR', payload });
		}
	};
};
