import React from 'react';
import { connect } from 'react-redux';
import SideNavItem from './SideNavItem';
import { FadeLoader } from 'react-spinners';
import css from '@emotion/css';

const override = css`
	display: block;
	margin: 1rem auto;
	border-color: red;
`;

const SideNavItemGroupList = ({
	items,
	formName,
	className,
	noImage,
	tripLoading,
	itemsLoading,
	refreshedValue,
	key,
}) => {
	return (
		<ul>
			{tripLoading || itemsLoading ? (
				<FadeLoader
					css={override}
					size={10}
					//size={"150px"} this also works
					color={'black'}
					//initializes own piece of state, was conflicting with state.loading
					loading={true}
				/>
			) : (
				<>
					{items && items.length
						? items.map((item, index) => {
								return (
									<SideNavItem
										key={key + '-item'}
										refreshedValue={refreshedValue}
										noImage={noImage}
										className={className}
										itemIndex={index}
										formName={formName}
										itemInfo={item}
									/>
								);
						  })
						: null}
				</>
			)}
		</ul>
	);
};
const mapStateToProps = (state) => ({
	tripLoading: state.trip.loading,
});

export default connect(mapStateToProps, null)(SideNavItemGroupList);
