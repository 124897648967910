import formTemplates from '../../Utilities/formTemplates';

export const setCustomForm = (formType, formData) => (dispatch) => {
	formData = formData ? formData : formTemplates[formType];
	dispatch({
		type: 'SET_FORM_DATA',
		payload: {
			formType,
			formData,
		},
	});
};
export const clearForm = () => (dispatch) => {
	dispatch({
		type: 'CLEAR_FORM_DATA',
	});
};
