import React from 'react';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import {
	setRedirect,
	removeRedirect,
} from '../../redux/Actions/redirectActions';

const redirectHOC = (WrappedComponent) => {
	const RedirectHOC = ({
		pathname,
		setRedirect,
		removeRedirect,
		location,
		...props
	}) => {
		const params = useParams();

		const returnDocumentTitle = (pathname, params) => {
			let tripDashboard = pathname.includes('dashboard/trip');
			tripDashboard = tripDashboard
				? `Rove - Trip Dashboard${params.id ? `- ${params.id}` : ''}`
				: null;
			let userDashboard = pathname.includes('dashboard/user');
			userDashboard = userDashboard
				? `Rove - User Dashboard${params.id ? ` - ${params.id}` : ''}`
				: null;

			let home = pathname === '/' ? 'Rove - Home' : null;
			let createItinerary =
				pathname.includes('create-itinerary') && params.id
					? 'Rove- Create Itinerary'
					: null;

			return tripDashboard || userDashboard || home || createItinerary;
		};

		const currentTitle = returnDocumentTitle(window.location.pathname, params);

		document.title = currentTitle || document.title;

		if (pathname) {
			const pathnameCopy = pathname;
			removeRedirect();
			return <Redirect to={pathnameCopy} />;
		}
		console.log('redirect rerender');

		return <WrappedComponent {...props} />;
	};

	const mapStateToProps = (state) => ({
		pathname: state.redirect.pathname,
	});

	return connect(mapStateToProps, {
		setRedirect,
		removeRedirect,
	})(RedirectHOC);
};

export default redirectHOC;
