export default {
	activity: {
		type: 'individual-activity',
		name: '',
		description: '',
		url: '',
		imageUrl: '',
		cityName: '',
		countryName: '',
		price: { currencyCode: 'USD', amount: '' },
		singleTime: false,
		start: '',
		end: '',
		paid: { success: false },
		inCart: false,
		authorized: { success: false },
		units: 1,
		timeLength: 1,
		dayOptions: {
			Monday: true,
			Tuesday: true,
			Wednesday: true,
			Thursday: true,
			Friday: true,
			Saturday: true,
			Sunday: true,
		},
		startTimeRange: '',
		endTimeRange: '',
		hotelPickup: true,
		groupCapacity: 0,
		selected: false,
		timeOptions: [],
		dateRange: {
			start: '',
			end: '',
		},
		timeInterval: 1,
		adminNotes: '',
	},
	transport: {
		trip: null,
		type: 'public-transport',
		name: '',
		description: '',
		url: '',
		startLocation: '',
		endLocation: '',
		imageUrl: '',
		units: 1,
		price: { currencyCode: 'USD', amount: '' },
		start: null,
		end: null,
		paid: { success: false },
		authorized: { success: false },
		inCart: false,
		groupCapacity: 0,
		timeLength: 1,
		selected: false,
		startTimeRange: '',
		endTimeRange: '',
		timeOptions: [],
		dateRange: {
			start: '',
			end: '',
		},
	},
};

export const transportFormTemplate = {
	trip: null,
	type: 'public-transport',
	name: '',
	description: '',
	url: '',
	startLocation: '',
	endLocation: '',
	imageUrl: '',
	units: 1,
	price: { currencyCode: 'USD', amount: '' },
	start: null,
	end: null,
	paid: { success: false },
	authorized: { success: false },
	inCart: false,
	groupCapacity: 0,
	timeLength: 1,
	selected: false,
	startTimeRange: '',
	endTimeRange: '',
	timeOptions: [],
	dateRange: {
		start: '',
		end: '',
	},
};
