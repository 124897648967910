import React from 'react';
import classNames from 'classnames';

const SNIPriceDifference = ({ isLess, isGreater, difference }) => {
	return difference ? (
		<span
			className={classNames({
				'is-greater': isGreater,
				'is-less': isLess,
			})}>
			{isGreater && `+$${difference}`}
			{isLess && `-$${difference * -1}`}
		</span>
	) : null;
};

export default SNIPriceDifference;
