const hotel_city_codes = require('./new_city_codes.json');
const new_city_codes = require('./new_city_codes.json');

export const toDollarStyle = (number, totalCurrencyCode) => {
	var formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: totalCurrencyCode,

		// These options are needed to round to whole numbers if that's what you want.
		//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
		//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
	});

	return formatter.format(number);
};

export const returnCitySearch = (searchInput) => {
	const lowerCase = searchInput.toLowerCase();
	console.log('lowerCase', lowerCase);
	console.log('hotel_city_codes', hotel_city_codes.slice(0, 5));
	const filteredCities = hotel_city_codes.filter((item) => {
		const { cityName, countryName } = item;
		if (cityName.includes(lowerCase) || countryName.includes(lowerCase)) {
			console.log('item', item);
			console.log('cityName.includes(lowerCase)', cityName.includes(lowerCase));
			console.log(
				'countryName.includes(lowerCase)',
				countryName.includes(lowerCase)
			);
		}
		return cityName.includes(lowerCase) || countryName.includes(lowerCase);
	});
	return filteredCities;
};

export const newReturnCitySearch = (searchInput) => {
	const split = searchInput.toLowerCase();

	const filteredCities = new_city_codes.filter((item) => {
		const { cityName, countryName } = item;

		return cityName.includes(split) || countryName.includes(split);
	});
	return filteredCities;
};
