import React from 'react';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { connect } from 'react-redux';

const SelectedButton = ({ id, handleClick, inItinerary }) => {
	return (
		<Fragment>
			<button
				className={`itinerary-modal-button ${inItinerary && 'in-itinerary'}`}
				onClick={handleClick ? handleClick : null}>
				{inItinerary ? 'Remove' : 'Add To Itinerary'}
			</button>
		</Fragment>
	);
};

SelectedButton.propTypes = {
	selectedItem: PropTypes.object,
};

const mapStateToProps = (state) => ({
	selectedItem: state.trip.selectedItem,
});

export default connect(mapStateToProps, null)(SelectedButton);
