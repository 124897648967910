import React from 'react';

const FormField = ({
	handleChange,
	type,
	name,
	step,
	title,
	value,
	required,
	handleBlur,
	cname,
}) => {
	return (
		<div className={`text-field ${type}`}>
			<label htmlFor={name}>{title}:</label>
			<input
				onBlur={(e) => {
					e.preventDefault();
					handleBlur && handleBlur();
				}}
				required={required}
				value={value || ''}
				type={type}
				name={name}
				step={step || 'false'}
				onChange={handleChange}
				className={cname}
			/>
		</div>
	);
};

export default FormField;
