import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logIn } from '../../redux/Actions/authActions';
import { Redirect } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import config from '../../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import redirectHOC from '../hoc/RedirectHOC';
toast.configure();

class SignIn extends Component {
	state = {
		email: '',
		password: '',
		success: false,
	};
	handleChange = (e) => {
		this.setState({
			[e.target.id]: e.target.value,
		});
	};
	canBeSubmitted() {
		const { email, password } = this.state;
		return email.length > 0 && password.length > 0;
	}
	handleSubmit = (e) => {
		e.preventDefault();
		if (this.canBeSubmitted()) {
			this.props.logIn({
				email: this.state.email,
				password: this.state.password,
			});
		}
	};

	render() {
		const isEnabled = this.canBeSubmitted();
		if (this.props.token || this.state.success === true)
			return <Redirect to='/dashboard/trip' />;
		return (
			<div className='auth-container'>
				<form className='white' onSubmit={this.handleSubmit}>
					<h5 className='grey-text text-darken-3'>Sign In</h5>
					<div className='text-field'>
						<label htmlFor='email'>Email</label>
						<input type='email' id='email' onChange={this.handleChange} />
					</div>
					<div className='text-field'>
						<label htmlFor='password'>Password</label>
						<input type='password' id='password' onChange={this.handleChange} />
					</div>
					<div className='flex-section'>
						<button className='cta-button' disabled={!isEnabled}>
							Login
						</button>
						{/* <div className='center red-text'>
							{authError ? <p>{authError}</p> : null}
						</div> */}
						<div>
							Don't have an account?
							<br />
							<NavLink to={config.SignedOutLinks.signup}>
								{config.SignedOutLinks.Signup}
							</NavLink>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		authError: state.auth.authError,
		token: state.auth.token,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		logIn: (payload) => dispatch(logIn(payload)),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(redirectHOC(SignIn));
