export const setAllMaps = (itineraryItems) => (dispatch) => {
	const maps = {};
	for (let key in itineraryItems) {
		const hasLength = itineraryItems[key].length;
		const map = hasLength ? {} : null;
		Array.isArray(itineraryItems[key]) &&
			itineraryItems[key].forEach((item) => {
				item = {
					data: item.data,
					trip: item.trip._id || item.trip,
					type: item.type,
				};
				map[JSON.stringify(item)] = true;
			});
		maps[key] = map;
	}
	dispatch({
		type: 'SET_ALL_MAPS',
		payload: maps,
	});
};

export const addMapItem = (key, item) => (dispatch) => {
	item = JSON.stringify(item);
	dispatch({
		type: 'ADD_MAP_ITEM',
		payload: { key, item },
	});
};
