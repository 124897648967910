import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

const requireAuth = (WrappedComponent) => {
	const AuthenticatedComponent = ({ ...props }) => {
		if (!localStorage.getItem('authToken')) {
			return <Redirect to='/sign-in' />;
		}
		return <WrappedComponent {...props} />;
	};

	const mapStateToProps = (state) => ({
		token: state.auth.token,
	});

	return connect(mapStateToProps, null)(AuthenticatedComponent);
};

export default requireAuth;
