import React from 'react';
import failureImg from '../../images/noun-x-2289990-E91831.svg';
import successImg from '../../images/noun-checkmark-3345771-0DC25E.svg';

import { FadeLoader } from 'react-spinners';
import css from '@emotion/css';
const override = css`
	display: block;
	margin: 0 auto;
	border-color: red;
`;

const LoadingOverlay = ({ success, loading }) => {
	return (
		<div className='loading-overlay'>
			{loading ? (
				<>
					<FadeLoader
						css={override}
						size={150}
						//size={"150px"} this also works
						color={'black'}
						//initializes own piece of state, was conflicting with state.loading
						loading={true}
					/>
				</>
			) : (
				<>
					{success ? (
						<div>
							<img src={successImg} alt='' />
							Success!
						</div>
					) : (
						<div>
							<img src={failureImg} alt='' />
							Something went wrong!
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default LoadingOverlay;
