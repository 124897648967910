import React from 'react';
import LoadingOverlay from '../Layout/LoadingOverlay';
import { useState } from 'react';
import FormField from '../CustomData/FormField';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import { sendMessage } from '../../redux/Actions/adminActions';

const UserHeader = ({ user_id, handleClick, sendMessage }) => {
	const [loading, setLoading] = useState(true);
	const [submitted, setSubmitted] = useState(false);
	const [success, setSuccess] = useState(false);
	const [formData, setFormData] = useState({
		user_id,
		subject: '',
		message: '',
	});

	const handleSubmit = async (e, close) => {
		setSubmitted(true);
		setLoading(true);

		try {
			const res = await sendMessage(formData);
			if (res.data.success) {
				setSuccess(true);
				setLoading(false);
			}
			setTimeout(() => {
				close();
			}, 3000);
		} catch (err) {
			setSuccess(false);
			setLoading(false);
			setTimeout(() => {
				setSubmitted(false);
				setLoading(true);
			}, 3000);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};
	return (
		<>
			<Popup
				trigger={
					<button
						onClick={handleClick}
						style={{
							backgroundColor: 'none',
							padding: 0,
							border: 'none',
						}}>
						Send Message
					</button>
				}
				modal>
				{(close) => (
					<div className='popup' id='popup'>
						<div className='popup__content'>
							{submitted && (
								<LoadingOverlay loading={loading} success={success} />
							)}
							<div className='popup__header'>
								<h3 className='popup__header--text'>Send message</h3>
								<a
									href='#'
									className='popup__close'
									onClick={(e) => {
										e.preventDefault();
										close();
									}}>
									<i className='fa fa-times'></i>
								</a>
							</div>
							<FormField
								value={formData.subject}
								name='subject'
								type='text'
								title='Subject'
								handleChange={handleChange}
							/>
							<div className='text-field'>
								<label htmlFor='message'>Message:</label>
								<textarea
									required
									value={formData.message}
									type='text'
									name='message'
									onChange={handleChange}
									maxLength='500'></textarea>
							</div>
							<button onClick={(e) => handleSubmit(e, close)}>submit</button>
						</div>
					</div>
				)}
			</Popup>
		</>
	);
};

const mapStateToProps = (state) => ({
	user_id: state.admin.selectedUser._id,
});

export default connect(mapStateToProps, { sendMessage })(UserHeader);
