export const airlines = {
	CX: {
		name: 'Pacific Airways',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717039/Airlines/Cathay_Pacific_Airways.png',
	},
	JL: {
		name: 'Japan Airlines',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717039/Airlines/Japan_Airlines.png',
	},
	AF: {
		name: 'Air France',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717039/Airlines/Air_France.png',
	},
	EI: {
		name: 'Aer Lingus',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717039/Airlines/Aer_Lingus.jpg',
	},
	'9W': {
		name: 'Jet Airways',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717039/Airlines/Jet_Airways.png',
	},
	AB: {
		name: 'Airberlin',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717039/Airlines/Airberlin.png',
	},
	KL: {
		name: 'KLM Royal Dutch Airlines',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717039/Airlines/KLM_Royal_Dutch_Airlines.png',
	},
	LX: {
		name: 'Swiss International Air Lines',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717039/Airlines/Swiss_International_AIr_Lines.jpg',
	},
	AZ: {
		name: 'Alitalia Airways',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717039/Airlines/Alitalia_Airways.png',
	},
	CI: {
		name: 'China Airlines',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717038/Airlines/China_Airlines.png',
	},
	'5J': {
		name: 'Cebu Pacific Air',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717038/Airlines/Cebu_Pacific_Air.png',
	},
	WN: {
		name: 'Southwest Airlines',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717038/Airlines/Southwest_Airlines.jpg',
	},
	AC: {
		name: 'Air Canada',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717038/Airlines/Air_Canada.png',
	},
	CA: {
		name: 'Air China',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717038/Airlines/Air_China.png',
	},
	SQ: {
		name: 'Singapore Airlines',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717038/Airlines/Singapore_Airlines.png',
	},
	QR: {
		name: 'Qatar Airways',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717038/Airlines/Qatar_Airways.png',
	},
	VA: {
		name: 'Virgin Australia',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717038/Airlines/Virgin_Australia.png',
	},
	KE: {
		name: 'Korean Air',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717038/Airlines/Korean_Air.png',
	},
	WS: {
		name: 'WestJet',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717038/Airlines/WestJet.jpg',
	},
	FR: {
		name: 'Ryanair',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717038/Airlines/Ryanair.png',
	},
	TG: {
		name: 'Thai Airways International',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717038/Airlines/Thai_Airways_International.png',
	},
	EC: {
		name: 'easyJet',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717038/Airlines/easyJet.jpg',
	},
	BR: {
		name: 'EVA Airway',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717038/Airlines/EVA_Airway.jpg',
	},
	AA: {
		name: 'American Airlines',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717037/Airlines/American_Airlines.jpg',
	},
	IB: {
		name: 'Iberia',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717037/Airlines/Iberia.png',
	},
	TK: {
		name: 'Turkish Airlines',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717037/Airlines/Turkish_Airlines.png',
	},
	F9: {
		name: 'Frontier Airlines',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717037/Airlines/Frontier_Airlines.jpg',
	},
	BA: {
		name: 'British Airways',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717037/Airlines/British_Airways.jpg',
	},
	DL: {
		name: 'Delta Air Lines',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717037/Airlines/Delta_Air_Lines.jpg',
	},
	AK: {
		name: 'Air Asia',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717037/Airlines/Air_Asia.png',
	},
	UA: {
		name: 'United Airlines',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717037/Airlines/United.jpg',
	},
	LH: {
		name: 'Lufthansa',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717037/Airlines/Lufthansa.png',
	},
	EK: {
		name: 'Emirates',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717037/Airlines/Emirates.png',
	},
	HA: {
		name: 'Hawaiian Airlines',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717037/Airlines/Hawaiian_Airlines.png',
	},
	AS: {
		name: 'Alaska Airlines',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1623717037/Airlines/Alaska_Airlines.jpg',
	},
	OZ: {
		name: 'Asiana Airlines',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1643562240/Airlines/Asiana_Airlines.jpg',
	},
	FI: {
		name: 'Iceland Air',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1643383390/Airlines/Icelandair.jpg',
	},
	KM: {
		name: 'Air Malta',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1643383352/Airlines/Air_Malta.jpg',
	},
	VS: {
		name: 'Virgin Atlantic',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1643562240/Airlines/Virgin_Atlantic.jpg',
	},
	VN: {
		name: 'Vietnam Airlines',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1643562240/Airlines/Vietnam_Airlines.jpg',
	},
	GE: {
		name: 'TransAsia Airways',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1643562240/Airlines/TransAsia_Airways.jpg',
	},
	TP: {
		name: 'TAP Portugal',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1643562240/Airlines/TAP_Portugal.jpg',
	},
	WY: {
		name: 'Oman Air',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1643562240/Airlines/Oman_Air.jpg',
	},
	B6: {
		name: 'JetBlue',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1643562240/Airlines/JetBlue.jpg',
	},
	FJ: {
		name: 'Fiji Airways',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1643562240/Airlines/Fiji_Airways.jpg',
	},
	AY: {
		name: 'Finnair',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1643562240/Airlines/Finnairz.jpg',
	},
	QU: {
		name: 'Croatia Airlines',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1643562240/Airlines/Croatia_Airlines.jpg',
	},
	BW: {
		name: 'Caribbean Airlines',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1643562240/Airlines/Caribbean_Airlines.jpg',
	},
	SN: {
		name: 'Brussels Airlines',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1643562240/Airlines/Brussels_Airlines.jpg',
	},
	NZ: {
		name: 'Air New Zealand',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1643562240/Airlines/Air_New_Zealand.jpg',
	},
	AI: {
		name: 'Air India',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1643562240/Airlines/Air_India.jpg',
	},
	UX: {
		name: 'Air Europa',
		image:
			'https://res.cloudinary.com/rove/image/upload/v1643562240/Airlines/Air_Europa.jpg',
	},
};
