import { get, getTripById, post } from '../../Utilities/api';
import { profileQuery } from './profileActions';
import { setRedirect } from './redirectActions';
import { setCurrentForm } from './trip';

export const addFlight = (payload) => {
	return (dispatch) => {
		dispatch({ type: 'ADD_FLIGHT_SUCCESS', payload });
	};
};
export const addHotel = (payload) => {
	return (dispatch) => {
		dispatch({ type: 'ADD_HOTEL_SUCCESS', payload });
	};
};
export const addActivity = (payload) => {
	return (dispatch) => {
		dispatch({ type: 'ADD_ACTIVITY_SUCCESS', payload });
	};
};

export const sendMessage = async (formData) => {
	try {
		const res = await post('/api/v1/admin/send-message', formData, true);
		return res;
	} catch (error) {
		return error;
	}
};

export const removeTripAuthorization = (payload) => (dispatch) => {
	dispatch({
		type: 'REMOVE_TRIP_AUTHORIZATION',
		payload,
	});
};

export const setDashBoard = () => async (dispatch) => {
	try {
		const authRequired = true;
		const res = await get('/api/v1/admin/me', {}, authRequired);

		const authItems = {
			flights: [],
			transport: [],
			activities: [],
			lodging: [],
			amadeusCars: [],
		};
		let { allTrips, allUsers, user, pendingBookings, pendingCurations } =
			res.data;
		const getAllAuth = (key) => {
			allTrips &&
				allTrips.length &&
				allTrips.forEach((trip) => {
					authItems[key] = [
						...authItems[key],
						...trip.authorized[key].map((item) => {
							return { ...item, trip, status: 'authorized' };
						}),
					];
				});
		};
		getAllAuth('flights');
		getAllAuth('transport');
		getAllAuth('activities');
		getAllAuth('lodging');
		getAllAuth('amadeusCars');

		if (res.data) {
			dispatch(profileQuery(user));
			dispatch(tripsQuery(allTrips));
			dispatch(usersQuery(allUsers));
			dispatch(
				setAuthorizedItems({ authItems, pendingBookings, pendingCurations })
			);
			dispatch({ type: 'SET_LOADING', payload: false });
		}
	} catch (err) {
		if (err.response.status === 401) {
			dispatch(setRedirect('/unauthorized'));
		}
		console.log(err.response.status);
	}
};

export const getTrips = () => async (dispatch) => {
	try {
		const authRequired = true;
		const res = await get('/api/v1/admin/fetch-trips', {}, authRequired).catch(
			(err) => {
				throw err.message;
			}
		);
		if (res.data && res.data.trips) dispatch(tripsQuery(res.data.trips));
	} catch (err) {
		console.log(err.message);
	}
};

export const handleFeePurchase = (item) => async (dispatch) => {
	dispatch({
		type: 'AUTH_ITEM_LOADING',
	});
	const now = new Date();
	const reduxKey =
		item.type === 'booking' ? 'pendingBookings' : 'pendingCurations';
	const updatedPurchase = {
		...item.purchase,
		[item.type]: {
			...item.purchase[item.type],
			paidOn: now,
		},
	};

	try {
		const res = await post(
			'/api/v1/data/private/purchase/update',
			updatedPurchase,
			true
		);
		const { savedPurchase } = res.data;
		dispatch({
			type: 'FILTER_PURCHASE',
			payload: {
				key: reduxKey,
				savedPurchase,
			},
		});
		if (savedPurchase) {
			dispatch(setCurrentForm(''));
		}
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const addUserAttachment = (payload) => (dispatch) => {
	dispatch({
		type: 'ADD_USER_ATTACHMENT',
		payload,
	});
};

export const setAuthorizedItems = (payload) => (dispatch) => {
	dispatch({
		type: 'SET_AUTHORIZED_ITEMS',
		payload,
	});
};

export const filterAuthItem = (key, id) => (dispatch) => {
	dispatch({ type: 'FILTER_AUTH_ITEM', payload: { key, id } });
};
export const removeFromItinerary = (id, key, nested) => (dispatch) =>
	nested
		? dispatch({
				type: 'REMOVE_ITINERARY_NESTED',
				payload: { key, id },
		  })
		: dispatch({
				type: 'REMOVE_ITINERARY_UNNESTED',
				payload: { key, id },
		  });

export const removeModalSelected = () => (dispatch) =>
	dispatch({
		type: 'REMOVE_MODAL_SELECTED',
	});

export const setCustomStartEnd =
	({ start, end, id, key }, nested) =>
	(dispatch) =>
		nested
			? dispatch({
					type: 'SET_START_END_NESTED',
					payload: { start, end, id, key },
			  })
			: dispatch({
					type: 'SET_START_END_UNNESTED',
					payload: { start, end, id, key },
			  });

export const addToItinerary = (id, key, nested) => (dispatch) =>
	nested
		? dispatch({
				type: 'ADD_ITINERARY_NESTED',
				payload: { key, id },
		  })
		: dispatch({
				type: 'ADD_ITINERARY_UNNESTED',
				payload: { key, id },
		  });

export const selectFlightItem =
	({ key, id }) =>
	(dispatch) =>
		dispatch({
			type: 'SELECT_NESTED_ITEM',
			payload: { key, id },
		});
export const selectNestedItem =
	({ key, id }) =>
	(dispatch) =>
		dispatch({
			type: 'SELECT_NESTED_ITEM',
			payload: { key, id },
		});

export const selectUnnestedItem =
	({ key, id }) =>
	(dispatch) =>
		dispatch({
			type: 'SELECT_UNNESTED_ITEM',
			payload: { key, id },
		});

export const clearReviewData = () => (dispatch) => {
	dispatch({
		type: 'CLEAR_REVIEW_DATA',
	});
};

export const selectUser = (id, loading) => async (dispatch) => {
	try {
		const res = await get(`/api/v1/admin/fetch-user/${id}`, {}, true);
		dispatch({
			type: 'SELECT_USER',
			payload: { user: res.data.data, loading: loading },
		});
	} catch (error) {
		console.log(error);
	}
};
export const removeUser = () => (dispatch) => {
	dispatch({
		type: 'REMOVE_USER',
	});
};
export const select =
	({ key, id }) =>
	(dispatch) =>
		dispatch({
			type: 'SELECT_UNNESTED_ITEM',
			payload: { key, id },
		});

export const addTransport = (payload) => {
	return (dispatch) => {
		dispatch({ type: 'ADD_TRANSPORT_SUCCESS', payload });
	};
};
export const usersQuery = (payload) => {
	return (dispatch) => {
		dispatch({ type: 'USERS_QUERY_SUCCESS', payload });
	};
};
export const tripsQuery = (payload) => {
	return (dispatch) => {
		dispatch({ type: 'TRIPS_QUERY_SUCCESS', payload });
	};
};
export const uploadUserData = (payload) => {
	return (dispatch) => {
		dispatch({ type: 'UPLOAD_USER_DATA_SUCCESS', payload });
	};
};
export const viewItinerary = (payload) => {
	return (dispatch) => {
		dispatch({ type: 'VIEW_ITINERARY_SUCCESS', payload });
	};
};
export const uploadTripData = (payload) => {
	return (dispatch) => {
		dispatch({ type: 'UPLOAD_TRIP_DATA_SUCCESS', payload });
	};
};
export const pushAdminTripSelections = () => {
	return (dispatch) => {
		dispatch({ type: 'PUSH_ADMIN_SELECTIONS' });
	};
};

export const setSelectedTrip = (payload) => async (dispatch) => {
	try {
		let res;
		if (typeof payload === 'string') {
			res = await getTripById(payload);
		} else {
			res = payload;
		}
		await dispatch({ type: 'SET_SELECTED_TRIP', payload: { ...res } });
		return true;
	} catch (err) {
		console.log(err);
		return false;
	}
};

export const removeSelectedTrip = () => {
	return (dispatch) => {
		dispatch({ type: 'REMOVE_TRIP_SUCCESS' });
	};
};

export const updateLodgingMedia = (payload) => {
	return (dispatch) => {
		dispatch({ type: 'UPDATE_LODGING_MEDIA', payload });
	};
};
