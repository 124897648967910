// rates/rateDetailsInfo/extraRateTypeInfo/rateCategory
export const rateCategories = {
	'002': 'Inclusive',
	'006': 'Convention',
	'007': 'Corporate',
	'009': 'Government',
	'011': 'Leisure',
	'019': 'Association',
	'020': 'Business',
	'021': 'Consortium',
	'022': 'Credential',
	'023': 'Industry',
	'024': 'Standard',
	G: 'General',
};

// rates/rateDetailsInfo/tariffInfo/rateType
export const rateTypes = {
	1: 'Hourly',
	2: 'Total',
	3: 'Daily',
	4: 'Monthly',
	5: 'Weekend',
	6: 'Weekly',
	906: 'estimated total',
	MUA: 'Mark-up amount',
	SLP: 'Selling price',
};

// rates/rateDetailsInfo/tariffInfo/rateAmountQualifier
export const rateAmountQualifiers = {
	113: 'Prepaid amount',
	901: 'Rate guaranteed',
	902: 'Rate quoted',
	FLD: 'FlightDate Segment Yield',
	GOD: 'Generic OnD Yield',
	GRP: 'Minimum Group Yield',
	GSG: 'Generic segment Yield',
	LBP: 'Leg bid price',
	OND: 'OnD Yield (Origin and Destination)',
	REV: 'Revenue Contribution',
	SBP: 'Segment bid price',
	SDR: 'Segment Yield (Segment Date Range)',
	SLB: 'Sum of leg bid prices',
	TOD: 'True OnD yield',
	TSG: 'True segment yield',
};

// rates/rateDetailsInfo/associatedCharges/chargeType
export const chargeTypes = {
	1: 'Drop charge/ One Way Fee',
	10: 'Adult',
	107: 'CDW (Collision damage waiver)',
	108: 'Surcharge',
	11: 'Child',
	12: 'Employee',
	13: 'Special equipment',
	2: 'Extra day',
	3: 'Extra week',
	4: 'Extra hour',
	44: 'Theft Protection(TP)',
	45: 'Tax',
	5: 'Extra month',
	6: 'Per mile',
	7: 'Per kilometer',
	8: 'Free miles',
	9: 'Free kilometers',
	COL: 'Collection Charge',
	COV: 'Coverage Policy',
	CPN: 'Coupon',
	DEL: 'Delivery',
	NSF: 'No Show Fee',
	TAC: 'Total of Additional Charges',
};

// rates/rateDetailsInfo/associatedCharges/chargePeriodTypeCode
export const chargePeriodTypes = {
	1: 'Per day',
	2: 'Per week',
	3: 'Per month',
	4: 'Per rental',
	5: 'Per hour',
	6: 'Per minute',
	AMP: 'And Month Period',
	AYP: 'And Year Period',
	BMP: 'Between Month Period',
	BP: 'Between Period',
	BYP: 'Between Year Period',
	EXS: 'Excess',
	FDP: 'From Day Period',
	FKD: 'From Kilometer Distance',
	FMD: 'From Miles Distance',
	FMP: 'From Month Period',
	FP: 'From Period',
	FWP: 'From Week Period',
	LBY: 'Liability',
	MAX: 'Maximum value',
	MND: 'Maximum Number of Days',
	PK: 'Per Kilometer',
	PM: 'Per Miles',
	PMD: 'Per Modification',
	PMV: 'Per Movment',
	PWE: 'Per Week End',
	TDP: 'To Day Period',
	TKD: 'To Kilometer Distance',
	TMD: 'To Miles Distance',
	TMP: 'To Month Period',
	TP: 'To Period',
	TWP: 'To Week Period',
};

export const vehicleTypeOwners = {
	13: 'ICAO (International Civil Aviation Organization)',
	2: 'CEC (Commission of the European Communities)',
	3: 'IATA (International Air Transport Association)',
	5: 'ISO (International Organization of Standardization)',
	6: 'IATCI',
	ACR: 'ACRISS - Association of Car Rental Industry Systems Standards',
	ZZZ: 'Mutually defined',
};

export const carCompanyAccessLevel = {
	CA: 'Complete Access',
	CP: 'Complete Access Plus',
	SA: 'Standard Access',
};

export const locationType = {
	DOP: 'Dropoff Location',
	PUP: 'Pickup Location',
};

export const quantityQualifier = {
	DLD: 'Delay at Destination',
	DLO: 'Delay at Origin',
};

// rates/sizedPictures/vehicleInformation/vehSpecialEquipm
export const vehicleSpecialEquipment = {};

// rates/sizedPictures/vehicleInformation/vehicleInfo/qualifier

export const vehicleInfoQualifiers = {};

// rates/sizedPictures/vehicleInformation/vehicleInfo/unit
export const vehicleInfoUnits = {};

// rates/sizedPictures/vehicleInformation/freeTextDetails/textSubjectQualifier
export const vehicleSubjectQualifier = {};

// rates/sizedPictures/vehicleInformation/freeTextDetails/informationType

// rates/sizedPictures/vehicleInformation/freeTextDetails/companyId

// rates/sizedPictures/vehicleInformation/freeTextDetails/source

// rates/sizedPictures/vehicleInformation/freeTextDetails/encoding

// rates/sizedPictures/vehicleInformation/nonNumericalAttributes/attributeType

// rates/sizedPictures/pictureCaption/freeTextDetails/textSubjectQualifier

// rates/sizedPictures/pictureCaption/freeTextDetails/informationType

// rates/sizedPictures/pictureCaption/freeTextDetails/source

// rates/sizedPictures/pictureCaption/freeTextDetails/encoding

// rates/loyaltyNumbersList/discountNumbers/customerReferenceInfo/referenceQualifier

// rates/rateDetailsInfo/extraRateTypeInfo/processIndicator

// rates/rateStatus/statusCode

// rates/ruleInfo/ruleDetails/type

// rates/ruleInfo/ruleDetails/quantityUnit

// rates/ruleInfo/ruleDetails/amountQualifier

// rates/ruleInfo/ruleDetails/paymentType
// rates/rateMarketingInfo/freeTextQualification/informationType

// rates/rateMarketingInfo/freeTextQualification/companyId

export const specialEquipment = {
	ABS: 'Anti-lock Brake System',
	AIR: 'Air conditioning',
	ATD: 'Anti-Theft Device',
	BYC: 'Bycicle rack',
	CAL: 'Car alarm',
	CBR: 'CB Radio',
	CCT: 'Cruise control',
	CLS: 'Central Locking System',
	CSB: 'Baby seat',
	DAB: 'Drive side airbag',
	DSL: 'Diesel',
	FMR: 'FM Radio',
	FRT: 'Front-wheel drive',
	HCH: 'Hatchback',
	HCL: 'Left-hand drive',
	HCR: 'Right-hand drive',
	ICS: 'Integrated Child Safety seat',
	LDP: 'Laser Disk Player',
	LFF: 'Lead Free Fuel',
	LUG: 'Luggage rack',
	NSK: 'Non smoker car',
	PAB: 'Power-Assisted Brake',
	PAS: 'Power-Assisted Steering',
	PAX: 'Number of passengers',
	PDL: 'Power Door Locks',
	PHN: 'Mobile phone',
	PWS: 'Power windows',
	RAD: 'Radio',
	RSB: 'Rear Seat Belt',
	SKR: 'Ski rack',
	SKV: 'Ski equipped vehicle',
	SNO: 'Snow chains',
	STR: 'Winter tires',
	SUR: 'Sun roof',
	TAP: 'Cassette player',
	TEL: 'Telephone',
	TRH: 'Trailer hitch',
	TSW: 'Tilt Steering Wheel',
	TVI: 'Television',
};

export const bookingCodes = {
	C: 'Business Class',
	F: 'First Class',
	M: 'Economy Standard Class',
	W: 'Economy Premium Class',
};

export const accrisCategory = {
	M: 'Mini',
	N: 'Mini Elite',
	E: 'Economy',
	H: 'Economy Elite',
	C: 'Compact',
	D: 'Compact Elite',
	I: 'Intermediate',
	J: 'Intermediate Elite',
	S: 'Standard',
	R: 'Standard Elite',
	F: 'Fullsize',
	G: 'Fullsize Elite',
	P: 'Premium',
	U: 'Premium Elite',
	L: 'Luxury',
	W: 'Luxury Elite',
	X: 'Special',
	O: 'Oversize',
};

export const accrisBodyType = {
	B: '2-3 Door',
	C: '2-4 Door',
	D: '4-5 Door',
	W: 'Wagon/Estate',
	V: 'Passenger Van',
	L: 'Limousine',
	S: 'Sport',
	T: 'Convertible',
	F: 'SUV',
	J: 'Open Air All Terrain',
	X: 'Special',
	P: 'Pickup (regular cab) 2 door',
	Q: 'Pickup (double cab) 4 door',
	Z: 'Special Offer Car',
	E: 'Coupe',
	M: 'Monospace',
	R: 'Recreational Vehicle',
	H: 'Motor Home',
	Y: '2 Wheel Vehicle',
	N: 'Roadster',
	G: 'Crossover',
	K: 'Commercial Van/Truck',
};

export const accrisDriveType = {
	M: 'Manual (unspecified drive)',
	N: 'Manual 4WD',
	C: 'Manual AWD',
	A: 'Automatic (unspecified drive)',
	B: 'Automatic 4WD',
	D: 'Automatic AWD',
};

export const accrisFuelAir = {
	R: 'Unspecified Fuel/Power With Air',
	N: 'Unspecified Fuel/Power Without Air',
	D: 'Diesel Air',
	Q: 'Diesel No Air',
	H: 'Hybrid Air',
	I: 'Hybrid Plug in Air',
	E: 'Electric (Distance < 250mi/400km) Air',
	C: 'Electric Plus (Distance ≥ 250mi/400km) Air',
	L: 'LPG/Compressed Gas Air',
	S: 'LPG/Compressed Gas No Air',
	A: 'Hydrogen Air',
	B: 'Hydrogen No Air',
	M: 'Multi Fuel/Power Air',
	F: 'Multi fuel/power No Air',
	V: 'Petrol Air',
	Z: 'Petrol No Air',
	X: 'Ethanol Air',
	// X: 'Ethanol No Air',
};

export const airConditioning = {
	R: 'Unspecified Fuel/Power With Air',
	D: 'Diesel Air',
	H: 'Hybrid Air',
	I: 'Hybrid Plug in Air',
	E: 'Electric (Distance < 250mi/400km) Air',
	C: 'Electric Plus (Distance ≥ 250mi/400km) Air',
	L: 'LPG/Compressed Gas Air',
	A: 'Hydrogen Air',
	M: 'Multi Fuel/Power Air',
	V: 'Petrol Air',
	X: 'Ethanol Air',
};

export const bootSizeCodes = {
	0: 'No boot',
	1: 'One small suitcase',
	2: 'Two small suitcases',
	3: 'One large and one small suitcase',
	4: 'One large and two small suitcases',
	5: 'Two large and one small suitcase',
	6: 'Two large and two small suitcases',
	7: 'Three large and one small suitcase',
	8: 'Three large and two small suitcases',
	9: 'Three large and three small suitcases',
	10: 'More than three large suitcases',
	19: 'Depends on the number of passengers',
	20: 'Truck or van',
};

export const bootSizeApprox = {
	0: '0',
	1: '1',
	2: '2',
	3: '~ 2',
	4: '~ 3',
	5: '~ 3',
	6: '~ 4',
	7: '~ 4',
	8: '~ 5',
	9: '~ 6',
	10: '3+',
	19: 'Depends on the number of passengers',
	20: 'Truck or van',
};
