import React from 'react';
import moment from 'moment-timezone';
import noImage from '../images/noun_Camera_prohibited_1554484.svg';

import datetimeDifference from 'datetime-difference';
import SegmentSection from '../Components/ItineraryReview/ItinerarySidenav/selectedModal/SegmentSection';
import { toDollarStyle } from './Utils';
const timezones = require('./timezone-codes.json');

export const returnSameTimezone = (departure, arrival) => {
	const startDate =
		departure &&
		departure.date &&
		moment(new Date(departure.date)).format().slice(0, 10);
	const endDate =
		arrival &&
		arrival.date &&
		moment(new Date(arrival.date)).format().slice(0, 10);
	const startTimeZone =
		departure && departure.location && timezones[departure.location];
	const endTimeZone =
		arrival && arrival.location && timezones[arrival.location];

	let departureTime =
		startDate && departure && departure.date && startTimeZone
			? moment(`${startDate} ${departure.time}`).tz(startTimeZone)
			: null;
	let arrivalTime =
		endDate && arrival && arrival.time && startTimeZone
			? moment(`${endDate} ${arrival.time}`).tz(startTimeZone)
			: null;
	let expectedDeparture =
		departureTime && endTimeZone
			? departureTime.clone().tz(endTimeZone).format()
			: null;
	let results =
		expectedDeparture && arrivalTime
			? returnTravelTime(
					datetimeDifference(
						new Date(expectedDeparture.slice(0, expectedDeparture.length - 6)),
						new Date(
							arrivalTime.format().slice(0, arrivalTime.format().length - 6)
						)
					)
			  )
			: null;
	return results;
};

export const handleLayover = (start, end, location) => {
	const travelTimeString = returnTravelTime(
		datetimeDifference(
			returnNewDate(start.date, start.time),
			returnNewDate(end.date, end.time)
		)
	);
	const layoverLocation = `${start.airport_info['Airport Name']} (${start.location})`;
	return (
		<SegmentSection
			time={`${travelTimeString} ${layoverLocation}`}
			location={location}
			layover={true}
		/>
	);
};

export const returnTravelTime = ({ days, hours, minutes }) => {
	let text = '';
	if (days) {
		text = `${days}d`;
	}
	if (hours) {
		text = `${text} ${hours}h`;
	}
	if (minutes) {
		text = `${text} ${minutes}m`;
	}
	return text;
};

export const returnNewDate = (date, time) => {
	const newDate = new Date(date);
	if (time) {
		const hours = time.slice(0, 2);
		const min = time.slice(3);
		newDate.setHours(hours);
		newDate.setMinutes(min);
	}
	return newDate;
};

export const handleTime = (time) => {
	let ampm = 'AM';
	let hour = parseInt(time.slice(0, 2));
	if (hour >= 12) {
		hour = hour > 12 ? hour - 12 : 12;
		ampm = 'PM';
	}
	if (hour === 0) hour = 12;
	return hour + time.slice(2) + ` ${ampm}`;
};

export const handleDurationString = (string) => {
	let hours = parseInt(string.slice(0, 2));
	let mins = parseInt(string.slice(3));
	return `${hours} hr ${mins} min`;
};

export const returnTimeTable = (timeInterval, startTimeRange, endTimeRange) => {
	if (timeInterval && startTimeRange && endTimeRange) {
		let results = [];
		let startHour = parseInt(startTimeRange.slice(0, 2));
		let startMin = parseInt(startTimeRange.slice(3));

		const endHour = parseInt(endTimeRange.slice(0, 2));
		const endMin = parseInt(endTimeRange.slice(3));

		const intervalMinutes = (timeInterval % 1) * 60;
		const intervalHours = timeInterval - (timeInterval % 1);

		while (startHour <= endHour) {
			if (startHour === endHour && startMin > endMin) {
				break;
			}
			const timeString = `${startHour < 10 ? `0${startHour}` : startHour}:${
				startMin < 10 ? `0${startMin}` : startMin
			}`;
			results.push(timeString);
			startMin = startMin + intervalMinutes;
			if (startMin >= 60) {
				startHour++;
				startMin -= 60;
			}
			if (intervalHours) {
				startHour += intervalHours;
			}
		}
		return results;
	}
};

export const returnItemFields = (item, keys) => {
	const foundKeys = [];
	let keyMap = {};
	keys.forEach((key) => {
		keyMap[key] = true;
	});
	const results = {};

	const returnKeyValues = (item, currentKey) => {
		if (foundKeys.length === keys.length) {
			return null;
		}
		if (keyMap[currentKey] && !results[currentKey]) {
			results[currentKey] = item;
			foundKeys.push(currentKey);
			return null;
		}
		if (Array.isArray(item)) {
			for (let index of item) {
				returnKeyValues(index);
			}
		} else if (typeof item === 'object') {
			for (let key in item) {
				returnKeyValues(item[key], key);
			}
		} else return null;
	};

	returnKeyValues(item);

	return results;
};

export const checkItemStatus = (trip, item) => {
	const {
		activity,
		flight,
		lodging,
		transportation,
		amadeusCars,
		curation,
		booking,
	} = checkType(item && (item.type || item.data.type));
	if (trip && item) {
		const { paid, authorized, cart, selected } = trip;

		const checkStatusObject = (object, key, id) => {
			if (
				object &&
				object[key] &&
				object[key].length &&
				object[key].find((item) => item._id && item._id === id)
			)
				return true;
			else return false;
		};
		const checkAllStatus = (key) => {
			const itemPaid =
				paid && checkStatusObject(paid, key, item._id) ? 'Paid' : false;
			const itemAuthorized =
				authorized && checkStatusObject(authorized, key, item._id)
					? 'Authorized'
					: false;
			const itemSelected =
				selected && checkStatusObject(selected, key, item._id)
					? 'Selected'
					: false;
			const itemInCart =
				cart && checkStatusObject(cart, key, item._id) ? 'In Cart' : false;

			return { itemPaid, itemAuthorized, itemSelected, itemInCart };
		};

		if (activity) {
			return checkAllStatus('activities');
		}
		if (flight) {
			return checkAllStatus('flights');
		}
		if (lodging) {
			return checkAllStatus('lodging');
		}
		if (transportation) {
			return checkAllStatus('transport');
		}
		if (amadeusCars) {
			return checkAllStatus('amadeusCars');
		}

		if (curation || booking) {
			let itemPaid = false;
			let itemAuthorized = false;
			let itemSelected = false;
			let itemInCart = false;
			itemAuthorized =
				item.purchase[curation || booking] &&
				item.purchase[curation || booking].authorizedOn
					? 'Authorized'
					: false;
			itemPaid =
				item.purchase[curation || booking] &&
				item.purchase[curation || booking].success
					? 'Paid'
					: false;
			return {
				itemPaid,
				itemAuthorized,
				itemSelected,
				itemInCart,
			};
		}
	} else {
		return {
			itemPaid: false,
			itemAuthorized: false,
			itemSelected: false,
			itemInCart: false,
		};
	}
};

export const checkType = (type) => {
	const trip = type === 'trip' ? 'trip' : false;
	const activity =
		type === 'individual-activity' || type === 'group-activity'
			? 'activities'
			: false;
	const transportation =
		type === 'public-transport' || type === 'private-transport'
			? 'transport'
			: false;
	const lodging = type === 'hotel-offer' ? 'lodging' : false;
	const amadeusCars = type === 'amadeus-car' ? 'amadeusCars' : false;
	const flight = type === 'flight-offer' ? 'flights' : false;
	const key =
		activity || flight || lodging || transportation || amadeusCars || trip;
	const booking = type === 'booking';
	const curation = type === 'curation';
	return {
		activity,
		flight,
		lodging,
		transportation,
		booking,
		curation,
		amadeusCars,
		trip,
		key,
	};
};

export const extractTotalPerNight = (roomStay, conversion) => {
	const { Total, Rate } = returnItemFields(roomStay, ['Total', 'Rate']);
	const rateHasLength = Rate && Rate.length;
	const ratesEndIndex = rateHasLength && Rate.length - 1;
	const startDate =
		rateHasLength &&
		Rate[0].info &&
		Rate[0].info['EffectiveDate'] &&
		moment(new Date(Rate[0].info['EffectiveDate']));
	const endDate =
		rateHasLength &&
		moment(
			new Date(
				Rate[ratesEndIndex]['Total']
					? Rate[ratesEndIndex - 1]['info'].ExpireDate
					: Rate[ratesEndIndex]['info'].ExpireDate
			)
		);

	const timeLength = endDate.diff(startDate, 'days');
	let total = Total && Total.length && Total[0];

	if (conversion) {
		total = total && total.info && total.info.converted_total;
	} else {
		if (total && total.info && total.info['AmountAfterTax']) {
			total = total.info['AmountAfterTax'];
		} else {
			total = total['Taxes'].reduce((acc, curr) => {
				const taxAmount = parseInt(curr.info['Amount']);
				return acc + taxAmount;
			}, parseInt(total.info['AmountBeforeTax']));
		}
	}

	return timeLength && total ? toDollarStyle(total / timeLength, 'USD') : null;
};

export const returnRoomStayLength = (roomStay) => {
	const { Rate } = returnItemFields(roomStay, ['Rate']);

	const ratesEndIndex = Rate.length - 1;
	const startDate = Rate && moment(new Date(Rate[0].info['EffectiveDate']));
	const endDate =
		Rate &&
		moment(
			new Date(
				Rate[ratesEndIndex]['Total']
					? Rate[ratesEndIndex - 1]['info'].ExpireDate
					: Rate[ratesEndIndex]['info'].ExpireDate
			)
		);
	const timeLength = endDate.diff(startDate, 'days');
	return timeLength;
};

export const handleHotelImage = () => {};

export const handleImage = (media) => {
	const imageURIs =
		media && media.length
			? media.find(
					(item) =>
						item['ImageItems'] &&
						(item.info['InfoCode'] === '1' ||
							item.info['AdditionalDetailCode'] === '17')
			  )
			: null;
	let image =
		imageURIs &&
		imageURIs['ImageItems'] &&
		imageURIs['ImageItems'].length &&
		imageURIs['ImageItems'][0]['ImageItem'] &&
		imageURIs['ImageItems'][0]['ImageItem'].length &&
		imageURIs['ImageItems'][0]['ImageItem'][0]['ImageFormat'] &&
		imageURIs['ImageItems'][0]['ImageItem'][0]['ImageFormat'].length &&
		imageURIs['ImageItems'][0]['ImageItem'][0]['ImageFormat'].find(
			(item) =>
				parseInt(item.info['Width']) <= 300 &&
				parseInt(item.info['Width']) >= 150 &&
				item.info['Format'] === '5'
		);
	image = image && image['URL'][0];
	return image || noImage;
};

export const handleMultiday = (event) => {
	const isSameMonth = event.start.getMonth() === event.end.getMonth();
	const isSameDay = event.start.getDate() === event.end.getDate();

	return {
		isMultiDay: isSameDay && isSameMonth ? false : true,
		className: isSameDay && isSameMonth ? ' same-day' : '',
	};
};

export const returnRatePlanData = (item) => {
	const {
		CancelPenalty: cancelPenalties,
		GuaranteeType: guaranteeType,
		GuaranteeAccepted: guaranteesAccepted,
	} = returnItemFields(item, [
		'CancelPenalty',
		'GuaranteeType',
		'GuaranteeAccepted',
	]);
	return { guaranteeType, guaranteesAccepted, cancelPenalties };
};

export const handleTripEndDate = (trip) => {
	return trip.returnDate
		? trip.returnDate
		: trip.hotelDetails[trip.hotelDetails.length - 1].endDate;
};

export const handleURL = (selectedItem, lodging) => {
	if (lodging) {
		const url =
			selectedItem.data &&
			selectedItem.data.descriptive_info &&
			selectedItem.data.descriptive_info['ContactInfos'] &&
			selectedItem.data.descriptive_info['ContactInfos'][0] &&
			selectedItem.data.descriptive_info['ContactInfos'][0]['ContactInfo'][0] &&
			selectedItem.data.descriptive_info['ContactInfos'][0]['ContactInfo'][0][
				'URLs'
			] &&
			selectedItem.data.descriptive_info['ContactInfos'][0]['ContactInfo'][0][
				'URLs'
			][0]['URL'][0];

		return (
			url &&
			(url.slice(0, 6) === 'https:' || url.slice(0, 5) === 'http:') &&
			url
		);
	} else return selectedItem.url;
};

export const handleTitleDisplay = (
	selectedItem,
	{ activity, transportation, lodging, flight }
) => {
	if (selectedItem) {
		const { name, itineraries, data } = selectedItem;
		if (activity || transportation) return name;
		if (lodging) {
			let newName = data.hotel_info['info']['HotelName'];
			newName = newName.toLowerCase();
			return newName;
		}
		if (flight) {
			return `${itineraries[0].segments[0].departure.iataCode} - ${
				itineraries[0].segments[itineraries[0].segments.length - 1].arrival
					.iataCode
			}`;
		}
	}
};

export const handleDescription = (
	selectedItem,
	{ activity, transportation, lodging }
) => {
	if (selectedItem && (activity || transportation))
		return selectedItem.description;
	if (selectedItem && lodging) {
		const media =
			selectedItem &&
			selectedItem.data &&
			selectedItem.data.descriptive_info &&
			selectedItem.data.descriptive_info['HotelInfo'] &&
			selectedItem.data.descriptive_info['HotelInfo'][0] &&
			selectedItem.data.descriptive_info['HotelInfo'][0]['Descriptions'] &&
			selectedItem.data.descriptive_info['HotelInfo'][0]['Descriptions'][0][
				'MultimediaDescriptions'
			] &&
			selectedItem.data.descriptive_info['HotelInfo'][0]['Descriptions'][0][
				'MultimediaDescriptions'
			][0] &&
			selectedItem.data.descriptive_info['HotelInfo'][0]['Descriptions'][0][
				'MultimediaDescriptions'
			][0]['MultimediaDescription'];

		const description =
			media &&
			media.find(
				(item) =>
					item['TextItems'] &&
					(item.info['AdditionalDetailCode'] === '2' ||
						item.info['AdditionalDetailCode'] === '1') &&
					item.info['InfoCode'] === '1'
			);

		const descriptionText =
			description &&
			description['TextItems'][0]['TextItem'][0]['Description'][0]._;

		return descriptionText;
	}
	return null;
};

export const handleRating = (selectedItem, lodging) => {
	if (lodging) {
		const rating =
			selectedItem.data.descriptive_info &&
			selectedItem.data.descriptive_info['AffiliationInfo'] &&
			selectedItem.data.descriptive_info['AffiliationInfo'].length &&
			selectedItem.data.descriptive_info['AffiliationInfo'][0]['Awards'][0][
				'Award'
			][0]['info'].Rating;
		return rating;
	}
	return selectedItem.rating;
};
