import React from 'react';

const SegmentSection = ({ time, location, layover }) => {
	return (
		<div className={`flight__info-section ${layover && 'layover'}`}>
			<div className='circle'>{layover && '||'}</div>
			<time>
				{time} {layover && 'layover'}
			</time>{' '}
			<span>{location}</span>
		</div>
	);
};

export default SegmentSection;
