import { get, post, put } from '../../Utilities/api';
import { setItineraryFlights } from './flightActions';
import { addMapItem, setAllMaps } from './selectedItemMaps';

export const setTrip = (payload) => (dispatch) => {
	dispatch({
		type: 'SET_TRIP',
		payload,
	});
};
export const getTrip = (id) => async (dispatch) => {
	console.log('get trip func start');
	try {
		const authRequired = true;
		const res = await get(
			`/api/v1/admin/fetch-trip/${id}`,
			{},
			authRequired
		).catch((err) => {
			throw new Error(err.message);
		});
		if (res.data && res.data.trip) {
			dispatch(setAllMaps(res.data.trip.itineraryItems));
			dispatch(setTrip(res.data.trip));
			return true;
		}
	} catch (err) {
		const message = err.message;
		console.log(message);
		return false;
	}
};

export const setCurrentForm = (string) => (dispatch) => {
	dispatch(clearTripModal());
	if (string) {
		dispatch({
			type: 'SET_CURRENT_FORM',
			payload: string,
		});
	}
};

export const addItineraryHotelUrl = (hotelCode, url) => (dispatch) => {
	dispatch({
		type: 'ADD_ITINERARY_HOTEL_URL',
		payload: { hotelCode, url },
	});
};

export const clearTripModal = () => (dispatch) => {
	dispatch({
		type: 'CLEAR_TRIP_MODAL',
	});
};

export const setCurrentReviewItem = (item) => (dispatch) => {
	dispatch(clearTripModal());

	if (item) {
		dispatch({
			type: 'SET_CURRENT_REVIEW',
			payload: item,
		});
	}
};

export const setCurrentSearch = (string) => (dispatch) => {
	dispatch({
		type: 'SET_CURRENT_SEARCH',
		payload: string,
	});
};

export const updateTransport = (item) => async (dispatch) => {
	try {
		const res = await post(
			'/api/v1/data/private/trip/update-transport',
			{ item },
			true
		).catch((err) => {
			throw new Error(err.message);
		});
		if (res.data && res.data.success) {
			dispatch(
				editItineraryItem(
					'transport',
					{ ...res.data.data, index: item.index },
					item.index
				)
			);
			return true;
		}
	} catch (error) {
		return false;
	}
};

export const updateActivity = (item) => async (dispatch) => {
	try {
		const res = await post(
			'/api/v1/data/private/trip/update-activity',
			{ item },
			true
		).catch((err) => {
			throw new Error(err.message);
		});
		if (res.data && res.data.success) {
			dispatch(
				editItineraryItem(
					'activities',
					{ ...res.data.data, index: item.index },
					item.index
				)
			);
			return true;
		}
	} catch (error) {
		return false;
	}
};

export const setAttachments = (trip_id) => async (dispatch) => {
	try {
		const res = await post(
			'/api/v1/data/private/trip/get-documents',
			{ trip_id },
			true
		).catch((err) => {
			throw new Error(err.message);
		});
		if (res.data && res.data.trip_files) {
			dispatch({
				type: 'SET_ATTACHMENTS',
				payload: res.data.trip_files,
			});
			return true;
		}
	} catch (error) {
		return false;
	}
};

export const transportCache = (trip) => (dispatch) => {
	const cachedItems = localStorage.getItem(`${trip._id}-transportItems`);
	if (cachedItems) {
		dispatch({
			type: 'SET_TRIP',
			payload: {
				...trip,
				itineraryItems: {
					...trip.itineraryItems,
					transport: JSON.parse(cachedItems),
				},
			},
		});
	}
};

export const uploadDocument = (body) => async (dispatch) => {
	try {
		const res = await post(
			'/api/v1/data/private/trip/upload-document',
			body,
			true
		).catch((err) => {
			throw new Error(err.message);
		});
		if (res.data && res.data.success && res.data.attachment) {
			dispatch({
				type: 'ADD_TRIP_ATTACHMENT',
				payload: res.data.attachment,
			});
		}
		return true;
	} catch (error) {
		return error;
	}
};

export const updateAttachments = async (body) => {
	try {
		const res = await post(
			'/api/v1/data/private/trip/update-documents',
			body,
			true
		).catch((err) => {
			throw new Error(err.message);
		});
		return res;
	} catch (error) {
		console.log(error.message);
	}
};

export const deselectModalItem = () => (dispatch) => {
	dispatch({
		type: 'DESELECT_MODAL_ITEM',
	});
};

export const removeAttachment = (compareIndex) => (dispatch) => {
	dispatch({
		type: 'REMOVE_ATTACHMENT',
		payload: compareIndex,
	});
};

export const selectModalItem = (item) => (dispatch) => {
	dispatch({
		type: 'SELECT_MODAL_ITEM',
		payload: item,
	});
};

export const flightSearch = (trip_id) => async (dispatch) => {
	try {
		const authRequired = true;

		const res = await post(
			'/api/v1/amadeus_test/get_top_10_flights',
			{ trip_id },
			authRequired
		).catch((err) => {
			throw new Error(err.message);
		});
		if (res.data && res.data.updated_flights) {
			dispatch(setItineraryFlights(res.data.updated_flights, 'flights'));
			localStorage.setItem(
				`flightSearchTime${trip_id}`,
				JSON.stringify(new Date())
			);
			return true;
		}
	} catch (error) {
		return false;
	}
};

export const addTripUrl = (payload) => {
	return (dispatch) => {
		dispatch({
			type: 'ADD_TRIP_URL',
			payload,
		});
	};
};

export const removeByBookingCode = (hotelCode, bookingCode) => (dispatch) => {
	dispatch({
		type: 'REMOVE_BY_BOOKING_CODE',
		payload: { hotelCode, bookingCode },
	});
};

export const overwriteTrip = (trip) => async (dispatch) => {
	try {
		const authRequired = true;
		const res = await put(
			'/api/v1/admin/update-itinerary',
			trip,
			authRequired
		).catch((err) => {
			throw new Error(err.message);
		});
		if (res.data && res.data.itineraryItems) {
			dispatch({
				type: 'OVERWRITE_TRIP_SUCCESS',
				payload: res.data.itineraryItems,
			});
			return true;
		}
	} catch (err) {
		const message = err.message;
		console.log(message);
	}
};

export const removeTripAuthorization = (payload) => (dispatch) => {
	dispatch({
		type: 'REMOVE_TRIP_AUTHORIZATION',
		payload,
	});
};

export const addItineraryItem = (key, item) => (dispatch) => {
	dispatch(addMapItem(key, item));
	dispatch({
		type: 'ADD_ITINERARY_ITEM',
		payload: {
			key,
			item,
		},
	});
};

export const editItineraryItem = (key, item, index) => (dispatch) => {
	dispatch({
		type: 'EDIT_ITINERARY_ITEM',
		payload: {
			key,
			item,
			index,
		},
	});
};

export const editTripField = (key, value) => (dispatch) => {
	dispatch({
		type: 'EDIT_TRIP_FIELD',
		payload: {
			key,
			value,
		},
	});
};

export const addTripAttachment = (payload) => (dispatch) => {
	dispatch({
		type: 'ADD_TRIP_ATTACHMENT',
		payload,
	});
};

export const editFlightDetail = (key, value, index) => (dispatch) => {
	dispatch({
		type: 'EDIT_FLIGHT_DETAIL',
		payload: {
			key,
			value,
			index,
		},
	});
};

export const deleteItineraryItem = (key, index) => (dispatch) => {
	dispatch({
		type: 'DELETE_ITINERARY_ITEM',
		payload: {
			key,
			index,
		},
	});
};

export const editHotel = (index, key, value) => (dispatch) => {
	dispatch({
		type: 'EDIT_HOTEL',
		payload: { index, key, value },
	});
};

export const removeItemByIndex = (index, key) => (dispatch) => {
	dispatch({
		type: 'REMOVE_ITEM_BY_INDEX',
		payload: { index, key },
	});
};
