import React from 'react';
import { sanitizeCarData } from '../../Utilities/carHelpers';
import { sanitizeFlightData } from '../../Utilities/flightHelpers';
import { checkType } from '../../Utilities/helperFunctions';
import { sanitizeHotelData } from '../../Utilities/hotelHelpers';

const sanitizeItem = (WrappedComponent) => {
	const SanitizeItem = ({ itemInfo, ...rest }) => {
		let { type, data, itemIndex, prevData } = itemInfo;
		const {
			activity,
			flight,
			lodging,
			transportation,
			amadeusCars,
			key: itemKey,
		} = checkType(type);

		let sanitizedData, prevSanitized;
		if (flight) {
			sanitizedData = sanitizeFlightData(data);
			prevSanitized = prevData && sanitizeFlightData(prevData);
		}
		if (amadeusCars) {
			sanitizedData = sanitizeCarData(data, data.location);
			prevSanitized = prevData && sanitizeCarData(prevData, prevData.location);
		}
		if (activity || transportation) {
			sanitizedData = itemInfo;
		}

		if (lodging) {
			sanitizedData = sanitizeHotelData(data);
		}
		return (
			<WrappedComponent
				sanitizedData={sanitizedData}
				prevSanitized={prevSanitized}
				itemInfo={itemInfo}
				itemIndex={itemIndex}
				{...{
					activity,
					itemKey,
					flight,
					lodging,
					transportation,
					amadeusCars,
				}}
				{...rest}
			/>
		);
	};

	return SanitizeItem;
};

export default sanitizeItem;
