import React from 'react';
import { handleVehicleType } from '../../Utilities/carHelpers';

import edit from '../../images/noun-edit-2162449-17234d.svg';
import noImage from '../../images/noun_Camera_prohibited_1554484.svg';
import { setCustomForm } from '../../redux/Actions/customItemForm';
import { connect } from 'react-redux';
import {
	setCurrentForm,
	setCurrentReviewItem,
	deleteItineraryItem,
} from '../../redux/Actions/trip';
import sanitizeItem from '../hoc/SanitizeItem';
import classNames from 'classnames';
import SNIPriceDifference from './SNIPriceDifference';

const SideNavItem = (props) => {
	const {
		sanitizedData,
		prevSanitized,
		itemInfo,
		setCustomForm,
		setCurrentForm,
		activity,
		flight,
		lodging,
		transportation,
		amadeusCars,
		formName,
		itemIndex,
		setCurrentReviewItem,
		// noImage,
		deleteItineraryItem,
		itemKey,
	} = props;

	const returnImage = (data) => {
		if (flight || lodging) {
			return data.imageURL || noImage;
		}
		if (amadeusCars) {
			return data.pictureURL || noImage;
		}
		if (activity || transportation) {
			return data.imageUrl || noImage;
		}
	};

	const returnPrice = (data) => {
		if (flight || lodging) {
			return data.total;
		}
		if (amadeusCars) {
			return data.estimatedTotal.tariffInfo.rateAmount;
		}

		if (activity || transportation) {
			return data.price.amount;
		}
	};

	const returnMain = () => {
		if (flight) {
			const { departure, arrival, firstLegAirline } = sanitizedData;
			return (
				<>
					<h4>{firstLegAirline}</h4>
					<h5 className='title'>
						{`${departure && departure.location}`} -{' '}
						{`${arrival && arrival.location}`}{' '}
					</h5>
				</>
			);
		}
		if (amadeusCars) {
			return (
				<>
					<h4>{handleVehicleType(sanitizedData.vehicleTypeInfo)}</h4>
				</>
			);
		}
		if (activity || transportation) {
			return (
				<>
					<h4>{sanitizedData.name}</h4>
				</>
			);
		}
		if (lodging) {
			return <h4>{sanitizedData.hotelName}</h4>;
		}
	};
	const difference =
		prevSanitized &&
		parseFloat(returnPrice(sanitizedData), 2) -
			parseFloat(returnPrice(prevSanitized), 2);

	const isGreater = difference > 0;
	const isLess = difference < 0;

	console.log('sidenavitem rerendering');
	return (
		<div
			className={classNames({
				sidenav__item: true,
				image__container: formName === 'photoUrl',
				found: itemInfo.found,
				'not-found': itemInfo.notFound,
			})}
			// className={`sidenav__item${
			// 	formName === 'photoUrl' ? ' image__container' : ''
			// }`}
		>
			{formName === 'photoUrl' ? (
				<img
					className='trip-image'
					src={itemInfo}
					onError={({ currentTarget }) => {
						currentTarget.onerror = null; // prevents looping
						currentTarget.src = noImage;
					}}
				/>
			) : (
				<>
					<div className='sidenav__item-actions'>
						{activity || transportation ? (
							<button
								onClick={() => {
									setCustomForm(formName, { ...itemInfo, index: itemIndex });
									setCurrentForm(formName);
								}}>
								<img src={edit} alt='' />
							</button>
						) : null}
						<button
							onClick={() => {
								console.log(itemKey);
								deleteItineraryItem(itemKey, itemIndex);
							}}
							className='close'>
							+
						</button>
					</div>
					<button
						onClick={() => {
							setCurrentReviewItem({ itemInfo, itemIndex });
						}}
						className='sidenav__item-button'>
						<img
							src={returnImage(sanitizedData) || noImage}
							alt=''
							className='sidenav__item-image'
							onError={({ currentTarget }) => {
								currentTarget.onerror = null; // prevents looping
								currentTarget.className = 'sidenav__item-image no-image';
								currentTarget.src = noImage;
							}}
						/>
						<main className='sidenav__item-main'>{returnMain()}</main>
						<div className='price__container'>
							<span className='price'>${returnPrice(sanitizedData)}</span>
							<SNIPriceDifference
								isGreater={isGreater}
								isLess={isLess}
								difference={difference !== 0 && difference?.toFixed(2)}
							/>
						</div>
					</button>
				</>
			)}
		</div>
	);
};

export default connect(null, {
	setCustomForm,
	setCurrentForm,
	setCurrentReviewItem,
	deleteItineraryItem,
})(sanitizeItem(SideNavItem));
