import {
	accrisBodyType,
	accrisCategory,
	accrisDriveType,
	accrisFuelAir,
	airConditioning,
} from './cars';

import datetimeDifference from 'datetime-difference';

export const sanitizeCarData = (data, companyLocation) => {
	let {
		pickupDropoffLocations,
		pickupDropoffTime,
		rateDetailsInfo,
		sizedPictures,
		vehicleTypeInfo,
		termsAndConditions,
	} = data;

	const { category, type, transmission, feulAir, hasAirConditioning } =
		handleAccris(vehicleTypeInfo);

	const foundAssociatedCharges =
		rateDetailsInfo &&
		rateDetailsInfo.length &&
		rateDetailsInfo.find((item) => item.associatedCharges);

	let unlimitedMileage = foundAssociatedCharges.associatedCharges.find(
		(item) => item.chargeType === '8' && item.chargePeriodTypeCode === `004`
	);
	const miles = unlimitedMileage ? 'Unlimited Miles' : null;

	let { carModel, vehicleCharacteristic } = vehicleTypeInfo;
	let { vehicleTypeOwner, vehicleRentalPrefType } = vehicleCharacteristic;

	const pickupLocationObject = pickupDropoffLocations.find(
		(item) => item.locationType === 'PUP'
	);
	const dropoffLocationObject = pickupDropoffLocations.find(
		(item) => item.locationType === 'DOL'
	);

	const pickupLocation =
		companyLocation && pickupLocationObject
			? companyLocation[pickupLocationObject.locationDescription.name]
			: null;

	const dropoffLocation =
		companyLocation && dropoffLocationObject
			? companyLocation[dropoffLocationObject.locationDescription.name]
			: null;

	const locationName =
		pickupLocation &&
		pickupLocation.address &&
		pickupLocation.address.addressDetails &&
		pickupLocation.address.addressDetails.line1;

	const airportTerminalInfo =
		pickupLocation && pickupLocation.airportTerminalInfo;

	const airportCode = airportTerminalInfo && airportTerminalInfo.airportCode;

	const terminalName =
		airportTerminalInfo &&
		airportTerminalInfo.terminalInformation &&
		airportTerminalInfo.terminalInformation.name
			? airportTerminalInfo.terminalInformation.name
			: null;

	const terminalString = terminalName
		? terminalName === 'ALL'
			? 'All Terminals'
			: `Terminal ${terminalName}`
		: null;

	let { bootSize, pictureURL } = handleSizedPictureInfo(sizedPictures);

	bootSize =
		sizedPictures &&
		sizedPictures.vehicleInformation &&
		sizedPictures.vehicleInformation.nonNumericalAttributes &&
		sizedPictures.vehicleInformation.nonNumericalAttributes.find(
			(item) => item.attributeType === 'BS'
		);

	bootSize = bootSize ? bootSize.attributeDescription : null;

	const { estimatedTotal, perDay } = handlePriceDisplay(
		pickupDropoffTime,
		rateDetailsInfo
	);

	return {
		miles,
		carModel,
		vehicleCharacteristic,
		terminalString,
		terminalName,
		airportCode,
		airportTerminalInfo,
		pickupLocationObject,
		dropoffLocationObject,
		pickupLocation,
		dropoffLocation,
		locationName,
		vehicleTypeOwner,
		vehicleRentalPrefType,
		vehicleTypeInfo,
		bootSize,
		pictureURL,
		estimatedTotal,
		perDay,
		termsAndConditions,
		category,
		type,
		transmission,
		feulAir,
		hasAirConditioning,
	};
};

export const handleAccris = (vehicleTypeInfo) => {
	let { vehicleCharacteristic } = vehicleTypeInfo;
	let { vehicleRentalPrefType } = vehicleCharacteristic;
	const category = accrisCategory[vehicleRentalPrefType[0]];
	const hasAirConditioning = airConditioning[vehicleRentalPrefType[3]];
	const type = accrisBodyType[vehicleRentalPrefType[1]];
	const transmission = accrisDriveType[vehicleRentalPrefType[2]];
	const feulAir = accrisFuelAir[vehicleRentalPrefType[3]];
	return {
		category,
		type,
		transmission,
		feulAir,
		hasAirConditioning,
	};
};

export const handleVehicleType = (vehicleTypeInfo) => {
	const { category, type, transmission, feulAir } =
		handleAccris(vehicleTypeInfo);
	let { carModel, vehicleCharacteristic } = vehicleTypeInfo;
	let { vehicleTypeOwner } = vehicleCharacteristic;
	if (carModel) return `${carModel.toLowerCase()}`;
	else if (vehicleCharacteristic) {
		if (vehicleTypeOwner === 'ACR') {
			return `${category}/ ${type}/ ${transmission}/ ${feulAir}`;
		}
	} else return null;
};

export const handleSizedPictureInfo = (sizedPictures) => {
	let sizedPicturesItem =
		sizedPictures && sizedPictures.length && sizedPictures[0];

	let bootSize =
		sizedPicturesItem &&
		sizedPicturesItem.vehicleInformation &&
		sizedPicturesItem.vehicleInformation.nonNumericalAttributes &&
		sizedPicturesItem.vehicleInformation.nonNumericalAttributes.find(
			(item) => item.attributeType === 'BS'
		);

	let pictureURL =
		sizedPicturesItem &&
		sizedPicturesItem.imageURL &&
		sizedPicturesItem.imageURL.address &&
		sizedPicturesItem.imageURL.address.identifier
			? sizedPicturesItem.imageURL.address.identifier
			: null;
	return {
		pictureURL: pictureURL || '',
		bootSize: (bootSize && bootSize.attributeDescription) || null,
	};
};

export const handlePriceDisplay = (pickupDropoffTime, rateDetailsInfo) => {
	const { beginDateTime, endDateTime } = pickupDropoffTime;

	const beginYear = parseInt(beginDateTime.year);
	const beginMonth = parseInt(beginDateTime.month) - 1;
	const beginDay = parseInt(beginDateTime.day);
	const endYear = parseInt(endDateTime.year);
	const endMonth = parseInt(endDateTime.month) - 1;
	const endDay = parseInt(endDateTime.day);

	const beginDate = new Date(beginYear, beginMonth, beginDay);

	const endDate = new Date(endYear, endMonth, endDay);

	const difference = datetimeDifference(beginDate, endDate);

	const estimatedTotal = rateDetailsInfo.find(
		(item) => item.tariffInfo.rateType === '906'
	);

	const perDay =
		parseFloat(estimatedTotal.tariffInfo.rateAmount) / difference.days;

	return {
		estimatedTotal,
		perDay,
	};
};
