import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import UserHeader from './UserHeader';

const UserSide = ({ selectedUser }) => {
	const {
		email,
		trips,
		createdAt,
		firstName,
		lastName,
		middleNameOrInitial,
		dob,
		phoneNumber,
		_id,
	} = selectedUser;

	return (
		<div className='user-side'>
			{/* <div className='image-container'>
				<img src={image || profile} alt='user' />
			</div> */}
			<div className='user-info'>
				<h3 className='capitalize'>
					{`${firstName} ${middleNameOrInitial} ${lastName}`.toLowerCase()}
				</h3>
				<span className='sub-title'> Contact Information:</span>
				<ul className='user-info__list'>
					<li className='user-info__list-item'>Email: {email}</li>
					<li className='user-info__list-item'>
						Phone: {phoneNumber || 'N/A'}
					</li>
					<li className='user-info__list-item'>
						<UserHeader userId={_id} />
					</li>
				</ul>
				<span className='sub-title'> Basic Information</span>
				<ul className='user-info__list'>
					<li className='user-info__list-item'>
						Date of Birth: {dob ? moment(dob.slice(0, 10)).format('L') : 'N/A'}
					</li>
					<li className='user-info__list-item'>
						Member Since: {moment(createdAt).format('L')}
					</li>
					<li className='user-info__list-item'>Trips: {trips.length}</li>
				</ul>{' '}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	selectedUser: state.admin.selectedUser,
});

export default connect(mapStateToProps, null)(UserSide);
