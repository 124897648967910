import React from 'react';

const FlightPrice = ({ price, currency }) => {
	return (
		<div className='flight__price'>
			<h3>{price}/per person</h3>
		</div>
	);
};

export default FlightPrice;
