import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import config from '../../config';
import { signOut } from '../../redux/Actions/authActions';

const Navbar = ({ signOut, token }) => {
	const [signedIn, setSignedIn] = useState(false);
	useEffect(() => {
		if (token) setSignedIn(true);
	}, [token]);

	return (
		<nav className='navbar'>
			<Link to={config.SignedInLinks.tripDash} className='navbar__logo'>
				Rove
			</Link>
			{signedIn && (
				<div className='main-links'>
					<Link to={config.SignedInLinks.tripDash} className='nav-link'>
						Trips
					</Link>{' '}
					<Link to={config.SignedInLinks.userDash} className='nav-link'>
						Users
					</Link>
					<Link to={`/dashboard/destinations`} className='nav-link'>
						Destinations
					</Link>
					<Link to={`/dashboard/content-management`} className='nav-link'>
						Manage Content
					</Link>
					<button className='nav-link' onClick={signOut}>
						Sign Out
					</button>
				</div>
			)}
		</nav>
	);
};

const mapStateToProps = (state) => ({
	token: state.auth.token,
});
export default connect(mapStateToProps, { signOut })(Navbar);
