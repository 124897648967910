import { handleImage, returnItemFields } from './helperFunctions';

export const sanitizeHotelData = (data) => {
	const {
		RatePlanCode,
		Commission,
		descriptive_info,
		hotel_info,
		GuaranteeType: guaranteeType,
		booking_code: bookingCode,
		RoomRate,
	} = returnItemFields(data, [
		'RatePlanCode',
		'RoomTypeCode',
		'ChainCode',
		'HotelCode',
		'Commission',
		'descriptive_info',
		'hotel_info',
		'ContactInfos',
		'Text',
		'CancelPenalty',
		'GuaranteeType',
		'GuaranteeAccepted',
		'HotelCityCode',
		'booking_code',
		'RoomRate',
	]);

	console.log('data', data);

	const { HotelCode, ChainCode, HotelCityCode } = returnItemFields(hotel_info, [
		'HotelCode',
		'ChainCode',
		'HotelCityCode',
	]);
	const {
		Rates,
		Text: descriptionText,
		RoomTypeCode,
		Total,
	} = returnItemFields(RoomRate, ['Rates', 'Text', 'RoomTypeCode', 'Total']);
	const rates = Rates && Rates.length && Rates[0]['Rate'];
	const ratesHasLength = rates && rates.length;
	const start_date =
		ratesHasLength && rates[0].info && rates[0].info['EffectiveDate'];
	const end_date =
		ratesHasLength &&
		rates[rates.length - 1].info &&
		rates[rates.length - 1].info['ExpireDate'];

	const policy =
		descriptive_info &&
		descriptive_info['Policies'] &&
		descriptive_info['Policies'].length &&
		descriptive_info['Policies'][0]['Policy'] &&
		descriptive_info['Policies'][0]['Policy'].length
			? descriptive_info['Policies'][0]['Policy'].find(
					(item) => item['PolicyInfo']
			  )
			: null;
	const { CheckInTime: checkinTime, CheckOutTime: checkoutTime } =
		returnItemFields(policy, ['CheckInTime', 'CheckOutTime']);

	const {
		Descriptions,
		RefPoint,
		HotelName,
		CancelPenalty: cancelPenalties,
	} = returnItemFields(descriptive_info, [
		'Descriptions',
		'RefPoint',
		'HotelName',
		'CancelPenalty',
	]);
	console.log('descriptive_info', descriptive_info);
	const { URLs } = returnItemFields(descriptive_info['ContactInfos'], ['URLs']);
	const { MultimediaDescription } = returnItemFields(Descriptions, [
		'MultimediaDescription',
	]);

	let url = URLs && URLs.length && URLs[0] && URLs[0].URL && URL[0];
	let total = Total && Total.length && Total[0];
	if (data.hasConversion) {
		total = total && total.info && total.info.converted_total;
	} else {
		total = total && total.info && total.info['AmountAfterTax'];
	}
	total = parseFloat(total).toFixed(2);
	const imageURL = handleImage(MultimediaDescription);

	const { distance } = hotel_info;

	return {
		policy,
		checkinTime,
		checkoutTime,
		distance,
		descriptionText,
		address: hotel_info.address,
		ratePlanCode: RatePlanCode,
		roomTypeCode: RoomTypeCode,
		rates: Rates[0].Rate,
		hotelCode: HotelCode,
		commission: Commission[0],
		total,
		descriptions: Descriptions,
		refPoint: RefPoint,
		hotelName: HotelName,
		multimediaDescription: MultimediaDescription,
		imageURL,
		url,
		cancelPenalties,
		guaranteeType,
		chainCode: ChainCode,
		hotelCityCode: HotelCityCode,
		roomType: RoomTypeCode,
		startDate: start_date,
		endDate: end_date,
		bookingCode,
	};
};

export const sanitizeDescriptiveInfo = (descriptive_info, hotelInfo) => {
	const { Descriptions, HotelName } = returnItemFields(descriptive_info, [
		'Descriptions',
		'RefPoint',
		'HotelName',
		'ContactInfos',
		'Address',
	]);
	const { URLs } = returnItemFields(descriptive_info['ContactInfos'], ['URLs']);
	const { MultimediaDescription } = returnItemFields(Descriptions, [
		'MultimediaDescription',
	]);

	let url = URLs && URLs.length && URLs[0] && URLs[0].URL && URL[0];

	const imageURL = handleImage(MultimediaDescription);

	const { address, distance } = hotelInfo;

	return {
		imageURL,
		url,
		address,
		distance,
		hotelName: HotelName,
	};
};
