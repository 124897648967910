import React from 'react';
import { useState } from 'react';
import SideNavItemGroupList from './SideNavItemGroupList';
import formIcon from '../../images/noun-form-1708509-142350.svg';
import arrow from '../../images/noun-down-2367384-DEDDDD.svg';
import refresh from '../../images/noun-refresh-4810604-142350.svg';
import { connect } from 'react-redux';
import { setCurrentForm } from '../../redux/Actions/trip';
import { amadeusRefresh } from '../../Utilities/api';

const SideNavItemGroup = ({
	title,
	items,
	formName,
	setCurrentForm,
	noImage,
	openItems,
	setOpenItems,
	trip_id,
}) => {
	const [updatedItems, setUpdatedItems] = useState(null);
	const [itemsLoading, setItemsLoading] = useState(false);
	let className = '';
	const isAmadeus =
		formName === 'hotel' || formName === 'flight' || formName === 'carRental';

	for (let char of formName) {
		if (char === char.toUpperCase()) {
			className = className + `-${char.toLowerCase()}`;
		} else {
			className = className + char;
		}
	}

	const handleUpdateItems = async () => {
		setItemsLoading(true);
		const refreshedItems = await amadeusRefresh(trip_id, formName, items);
		console.log('items', refreshedItems);
		if (refreshedItems && refreshedItems.length) {
			setUpdatedItems(refreshedItems);
			setItemsLoading(false);
		} else {
			console.log('no items returned');
		}
	};

	return (
		<div className='item-group'>
			<div className='item-group__actions'>
				<button
					onClick={() =>
						setOpenItems({
							...openItems,
							[formName]: !openItems[formName] ? true : false,
						})
					}
					className='item-group__button'>
					<img src={arrow} alt='' className='arrow' />
					{title}
				</button>
				{isAmadeus ? (
					<button onClick={handleUpdateItems}>
						<img src={refresh} alt='' className='arrow' />
					</button>
				) : null}
				<button onClick={() => setCurrentForm(formName)}>
					<img src={formIcon} alt='' className='arrow' />
				</button>
				{/*  */}
			</div>
			{openItems[formName] ? (
				<SideNavItemGroupList
					key={formName + '-grouplist-key'}
					refreshedValue={!!updatedItems}
					noImage={noImage}
					className={className}
					formName={formName}
					items={updatedItems || items}
					itemsLoading={itemsLoading}
				/>
			) : null}
		</div>
	);
};

export default connect(null, { setCurrentForm })(SideNavItemGroup);
